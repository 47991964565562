import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => {
    return (
        <div className="container mt-4" align="center">
            <img src="budget_burning2_alt_500x298.webp" className="image" alt="Marketer jumping in excitement because his Google Ads results got so much better thanks to Hai Byte's automation solutions" />
            <br/>
            <h4 className="display-4 text-center mb-4 title">
                Page Not Found
            </h4>
            <br />
            <p className="mb-0" align="center" >
            Oooppss! The page you are looking for doesn't exist or has been moved.
            <br/>
            <br/>
            <Link exact to="/" className="nav-link" href="/#" style={{"fontSize": "20px", "fontWeight": "bold"}}>Return to the app</Link>
            </p>
            <br />
            <br />
            
        </div>
    )
}

export default NotFound