import React from 'react'


function ErrorComponent() {
    return (
        <div className="container mt-4" align="center">
            <img src="budget_burning2_alt_500x298.webp" className="image" alt="Marketer jumping in excitement because his Google Ads results got so much better thanks to Hai Byte's automation solutions" />
            <br/>
            <h4 className="display-4 text-center mb-4 title">
                Error
            </h4>
            <br />
            <p className="mb-0" align="center" >
            Oops! Something went wrong. Please try again later.
            <br/>
            <br/>
            <a className="nav-link" href="/" style={{"fontSize": "20px", "fontWeight": "bold"}}>Return to the app</a>
            </p>
            <br />
            <br />
            
        </div>
    )
}

function logErrorToService(error, errorInfo) {
    console.error('Logged error:', error, errorInfo);
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError:false };
    }

    componentDidCatch(error, errorInfo) {
        logErrorToService(error, errorInfo);
        this.setState({ hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return <ErrorComponent />;
        }
        return this.props.children;
    }
}


export default ErrorBoundary