const uniqueArray = ( ar ) => {
    var j = {};
  
    ar.forEach( function(v) {
      j[v+ '::' + typeof v] = v;
    });
  
  
    return Object.keys(j).map(function(v){
      return j[v];
    });
  }   

const sumByGroup = (data, target, groupby, returnKeys=false, filterby=null, filterValue=null) => {
    /*let costByWeek = {}
    data.forEach(item => {
        if (costByWeek[item[groupby]]) {
            costByWeek[item[groupby]] += item[target];
        } else {
            costByWeek[item[groupby]] = item[target];
        }
    });
    return costByWeek;*/
    let groupedData = {}
    let keys = uniqueArray(data.map(item => item[groupby]))
    if (returnKeys) {
        return keys
    }
    keys.forEach(key => {
        data.forEach(item => {
            if (item[groupby] === key && (filterValue == 'All' || item[filterby] == filterValue)) {
                if (groupedData[key]) {
                    groupedData[key] += item[target]
                } else {
                    groupedData[key] = item[target]
                }
            }
        })
    })
    /*
    keys.forEach(item => {
        if (groupedData[item[groupby]]) {
            groupedData[key] += 
        }
    })*/
    return Object.values(groupedData)
};

const groupByKeys = (data, keys) => {
    return Object.values(data.reduce((r, o) => {
        const key = keys.map(k => o[k]).join('|');
        if (!r[key]) {
            r[key] = { ...o, Task: '', Value: 0, week: '', cost: 0, impressions: 0, clicks: 0, interactions: 0, conv: 0, conv_value: 0, incremental_conv: 0, incremental_conv_value: 0, conversions: 0, conversions_value: 0, impressions_shop: 0, impressions_dv: 0, impressions_ser: 0, clicks_shop: 0, clicks_dv: 0, clicks_ser: 0, cost_shop: 0, cost_dv: 0, cost_ser: 0, conversions_shop: 0, conversions_dv: 0, conversions_ser: 0, conversions_value_shop: 0, conversions_value_dv: 0, conversions_value_ser: 0 };
        }

        r[key].week += (r[key].week ? ', ' : '') + o.week;
        r[key].cost += +o.cost;
        r[key].impressions += +o.impressions;
        r[key].clicks += +o.clicks;
        r[key].interactions += +o.interactions;
        r[key].conv += +o.conv;
        r[key].conv_value += +o.conv_value;
        r[key].incremental_conv += +o.incremental_conv;
        r[key].incremental_conv_value += +o.incremental_conv_value;
        r[key].cpc = r[key].cost / r[key].clicks;
        r[key].cpm = r[key].cost / r[key].impressions * 1000;
        r[key].interaction_rate = r[key].interactions / r[key].impressions * 100;
        r[key].cost_per_conv = r[key].cost / r[key].conv;
        r[key].conv_rate = r[key].conv / r[key].clicks * 100;
        r[key].incremental_cost_per_conv = r[key].cost / r[key].incremental_conv;
        r[key].incremental_conv_rate = r[key].incremental_conv / r[key].clicks * 100;
        r[key].incremental_conv_value_per_cost = r[key].incremental_conv_value / r[key].cost;

        r[key].conversions += +o.conversions;
        r[key].conversions_value += +o.conversions_value;

        r[key].impressions_shop += +o.impressions_shop;
        r[key].impressions_dv += +o.impressions_dv;
        r[key].impressions_ser += +o.impressions_ser;

        r[key].clicks_shop += +o.clicks_shop;
        r[key].clicks_dv += +o.clicks_dv;
        r[key].clicks_ser += +o.clicks_ser;

        r[key].cost_shop += +o.cost_shop;
        r[key].cost_dv += +o.cost_dv;
        r[key].cost_ser += +o.cost_ser;

        r[key].conversions_shop += +o.conversions_shop;
        r[key].conversions_dv += +o.conversions_dv;
        r[key].conversions_ser += +o.conversions_ser;

        r[key].conversions_value_shop += +o.conversions_value_shop;
        r[key].conversions_value_dv += +o.conversions_value_dv;
        r[key].conversions_value_ser += +o.conversions_value_ser;

        return r;
    }, {}));
}

const sumValues = (data, metric) => {
    let value = 0;
    data.reduce((r, o) => {
        value += +o[metric];
    });
    return value;
}

const replaceInArray = (arr, search, replace) => {
    for(var i=0; i < arr.length; i++) {
        arr[i] = arr[i].replace(search, replace);
    }
    return arr;
}

const checkForTrue = (data, col) => {
    let count = 0
    data.map(item => {
        if (item[col] == true) {
            count += 1;
        }
    })
    return count;
}

export {sumByGroup, groupByKeys, sumValues, replaceInArray, checkForTrue}
