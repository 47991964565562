// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useState, useEffect, Fragment} from 'react'
import {useCookies} from 'react-cookie'
import {useHistory} from 'react-router-dom'
import Message from './Message'
import MessageWarning from './MessageWarning'
import MessageSuccess from './MessageSuccess'

import {checkForTrue} from './Utils';


const Campaigns = () => {

    const [token, setToken, removeToken] = useCookies(['mytoken'])
    const [refreshToken, setRefreshToken, removeRefreshToken] = useCookies(['refreshToken'])
    let history = useHistory()

    // store campaign info from the api
    const [campaignInfo, setCampaignInfo] = useState([])
    const [userPreferences, setUserPreferences] = useState([])

    const [customerId, setCustomerId, removeCustomerID] = useCookies(['customer_id'])
    const [currency, setCurrency, removeCurrency] = useCookies(['currency'])
    const [cidStatus, setCidStatus, removeCidStatus] = useCookies(['cidStatus']) 
    const [campaignId, setCampaignId, removeCampaignID] = useCookies(['campaign_id'])

    // store billing status from the api
    const [billingStatus, setBillingStatus] = useState('APPROVED')


    // to filter the table
    const [status, setStatus] = useState("All but removed")

    // to filter the query to be sent to the api
    const [date, setDate] = useState("ALL_TIME")

    // messages to inform users
    const [message, setMessage] = useState(' Fetching your data... It will take a few seconds.')
    const [messageWarning, setMessageWarning] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')

    // Subscription status
    const [subscriptionStatus, setSubscriptionStatus] = useState('')

    // success message saying campaign was created
    // when user is redirected here after successfully creating a campaign
    // const [successMessage, setSuccessMessage, removeSuccessMessage] = useCookies(['successMessage'])

    

    // if there is no mytoken in the cookie, redirect user to the home page (denying access)
    useEffect(() => {
        if(!token['mytoken'] || token['mytoken'] == 'undefined') {
            // history.push('/')
            window.location.href = '/'
        }
    }, [token])

    // if there is a customer_id in the cookies
    // send it to the backend with the refreshToken
    // where they will be used to get the campaigns info associated with that customer_id and token
    useEffect(() => {
        if(customerId) {

            // tell user you are fetching their data
            setMessage(' Fetching your data... It can take a few seconds.');

            // check subscription status
            const data0 = {
                'mytoken': token['mytoken']
            }

            fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/get-subscription-status/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data0),
                
            })
            .then(resp0 => resp0.json())
            .then(resp0 => {
                if (resp0 !== null) {
                    setSubscriptionStatus(resp0);
                }
            })
            .catch(error => console.log(error))

            // Get user auto-apply preferences
            const data1 = {
                'customerId': customerId['customer_id']
            }

            fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/get-user-preferences/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data1),
                
            })
            .then(resp1 => resp1.json())
            .then(resp1 => {
                if (resp1 !== null) {
                    setUserPreferences(resp1);
                }
            })
            .catch(error => console.log(error))
            
            // Get campaign data if subscriptionStatus != 'trialEnded'
            if (subscriptionStatus == 'trialEnded') {
                setMessage('')
            } else if (subscriptionStatus) {
                // data to send to the backend
                const data = { 
                    'mytoken': token['mytoken'], 
                    'refreshToken': refreshToken['refreshToken'], 
                    'customer_id': customerId['customer_id'], 
                    'date_range': date
                }

                fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/get-campaigns/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token['mytoken']}`
                    },
                    body: JSON.stringify(data),
                    
                })
                .then(resp => resp.json())
                .then(resp => {
                    if (resp !== null) {
                        // console.log(resp);
                        setCampaignInfo(resp);
                        setMessage('')
                    } else if (resp === null) {
                        // console.log(resp);
                        setMessage('');
                        setMessageWarning('You do not have campaigns. Create a campaign that can start showing ads within one day.')

                    }
                })
                .catch(error => console.log(error))
            }
           
            
        }
    }, [customerId, refreshToken, token, date, subscriptionStatus])

    // if campaignInfo object has data, delete the 'fetching data' message
    // and get the billing status
    useEffect(() => {
        if(campaignInfo.length > 0) {
            setMessage('');

            // data to send to the backend
            const data2 = { 
                'mytoken': token['mytoken'], 
                'refreshToken': refreshToken['refreshToken'], 
                'customer_id': customerId['customer_id'], 
                'date_range': "using same model of reporting"
            }

            fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/get-billing/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data2),
                
            })
            .then(resp => resp.json())
            .then(resp => {
                if (resp !== null) {
                    console.log(resp);
                    setBillingStatus(resp);
                } else if (resp === null) {
                    console.log(resp);
                    setBillingStatus(resp);

                }
            })
            .catch(error => {
                console.log(error)
                setMessage('')
                setMessageWarning(toString(error))
            })
        }
    }, [campaignInfo, customerId, refreshToken, token])


    // filter campaigns by status
    const onChangeStatus = (e) => {
        setStatus(e.target.value)

    }

    // filter campaigns by date
    const onChangeDate = (e) => {
        setDate(e.target.value);
        setCampaignInfo([])

    }

    // redirect to step 1 of campaign creation 
    // when user clicks on 'Create campaign' button
    const create = () => {
        history.push('/create-campaign')
    }

    const mmm = () => {
        history.push('/performance')
    }

    // go back button
    const goAccountsList = () => {
        // if user has refresh token cookie, take them to Accounts page
        if (refreshToken['refreshToken']) {
            history.push('/googleads-accounts')
        // if not, taking them to the home page
        } else {
            history.push('/')
        }
        
    }

    // when user clicks on a row, the campaign_id will be saved as a cookie
    // so we show the details of that campaign in the next page
    // also, it will be used to update the campaign
    const onClick = e => {
        const campaignID = e.currentTarget.id
        setCampaignId("campaignID", campaignID);
        //history.push('/edit-campaign');

    }

    // handle campaign selection
    const handleChange = (item, e) => {
        let isChecked = e.target.checked;
        let campaign_id = item.campaign_id;
        
        // Post the value to CustomerID table
        // data to send to the backend
        const data = { 
            'customer_id': customerId['customer_id'],
            'campaign_id': campaign_id,
            'process_automation': isChecked
        }

        fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/get-campaign-id/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            },
            body: JSON.stringify(data),
        })
        .then(function(response) {    
            console.log(response.text());
        })
        .catch(error => console.log(error))
        
    }

    // handle user preference update
    const handlePreferenceChange = (e, recommendationType) => {
        
        let isChecked = e.target.checked;
        const data = { 
            'customerId': customerId['customer_id'],
            'recommendationType': recommendationType,
            'autoApply': isChecked
        }
        
        fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/update-user-preference/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['mytoken']}`
            },
            body: JSON.stringify(data),
        })
        .then(function(response) {    
            console.log(response.text());
        })
        .catch(error => console.log(error))

    }


    return (
        
    <div className="container mt-4">
        
        <br/>
        <h4 className="display-4 text-left mb-4 title" >
            Auto-optimization settings
        </h4> 

        <br/>
        <button type="button" className="btn btn-link" name="go back" 
        onClick={goAccountsList} >
            <i className="fas fa-arrow-left fa-2x"></i>
        </button>
        <br/>
        <br/>
        {/* if user does not have billing set up yet */}
        {billingStatus === 'APPROVED' ? null : 
        <Fragment>
            <div className="alert alert-danger alert-dismissible fade show" 
            role="alert" 
            style={{ 
                display: 'inline-block', 
                fontSize:'16px' 
                }}>
                <i className="fas fa-exclamation-triangle fa-fw"
                style={{marginRight: '5px'}}></i>
                Your account cannot show ads. 
                To start running your ads, 
                <a href="https://ads.google.com/home/" className="alert-link"
                target="_blank" rel="noopener noreferrer"> CLICK HERE </a> 
                to enter your billing information. 
                This link will open a new tab with Google Ads platform. 
                Go to Settings in the top menu, 
                and click on Billing & payments. 
                When you finish, 
                close that tab to continue working on Hai Byte.
                
            </div>
        </Fragment>  }

        {/* if user has campaigns, show this message */}
        {campaignInfo.length > 0 ? 
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <h6>Auto-apply the following auto-optimization recommendations</h6>
                    <div className="row">
                        <div className="col-sm">
                            <p><input type="checkbox" onClick={e => handlePreferenceChange(e, 'adGroupBidAdjuster')} defaultChecked={userPreferences['adGroupBidAdjuster']}></input> Ad group bid optimization</p>
                        </div>
                        <div className="col-sm">
                            <p><input type="checkbox" onClick={e => handlePreferenceChange(e, 'ngramOptimizer')} defaultChecked={userPreferences['ngramOptimizer']}></input> Keyword and negative keyword optimization</p>
                        </div>
                        <div className="col-sm">
                            <p><input type="checkbox" onClick={e => handlePreferenceChange(e, 'adOptimizer')} defaultChecked={userPreferences['adOptimizer']}></input> Pause low performing ads</p>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <p>Select which campaigns will be automatically optimized by HaiByte. Check the boxes for the campaigns that you want to include in the automatic optimization.
            Click a campaign if you want to see/edit the campaign's settings. 
            </p>
            <p> 
            For a deep-dive on how your campaigns are performing, move to the <strong>Performance analysis</strong> page.
            </p>
        </Fragment> : 
        // if user has zero campaigns yet, show this message
        <Fragment>
            <p>Oh! It seems you do not have ads running on Google yet. 
                Create a campaign to start selling more!
            </p>
        </Fragment>}
        

        <br/>

        {/* <div className="container" align="left"> */}
            
                {/* <div className="col-6"> */}
                    {/*<button onClick={create} className="btn btn-success">Create campaign</button> */}
                    {/* <button onClick={mmm} className="btn btn-success">Performance analysis</button> */}
                {/* </div> */}
            
        {/* </div> */}
        {/* <br/> */}

        {message ? <Message msg={message} /> : null}
        {messageWarning ? <MessageWarning msg={messageWarning} /> : null}
        {messageSuccess ? <MessageSuccess msg={messageSuccess} /> : null}

        {cidStatus['cidStatus'] == 'false' ?
            <Fragment>
                <p><strong>Auto-optimization not enabled</strong> for the account. Please enable auto-optimization at account level to enable it for campaigns. </p>
                <br/>
            </Fragment>
        : checkForTrue(campaignInfo, 'process_automation_status') > 0 ?
            <Fragment>
                <p><strong>Auto-optimization enabled</strong> for {checkForTrue(campaignInfo, 'process_automation_status')} campaigns</p>
                <br/>
            </Fragment>
        : cidStatus['cidStatus'] == 'true' ?
            <Fragment>
                <p><strong>Auto-optimization not enabled</strong> for any campaigns, even though the account is opted in. Please select campaigns to start optimizing the account.</p>
                <br/>
            </Fragment>    
        :
            <Fragment>
                <p><strong>Auto-optimization not enabled</strong> for any campaigns</p>
                <br/>
            </Fragment>    
        }

        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <p><i className="fas fa-filter"></i>  Filter by campaign status</p>
            
                    <div className="btn-group">
                        
                        <select className="form-select form-select-sm" onChange={onChangeStatus} value={status} aria-label="Filter table by campaign status">
                            
                            <option value="All">All</option>
                            <option value="All active">All active</option>
                            <option value="All but removed">All but removed</option>
                        </select>
                    </div>

                </div>

                <div className="col-sm" align='right'>
                    <p><i className="fas fa-filter"></i>  Filter by date</p>
            
                    <div className="btn-group" >
                        
                    <select className="form-select form-select-sm" onChange={onChangeDate} value={date} aria-label="Filter table by date">
                            
                            <option value="TODAY">Today</option>
                            <option value="YESTERDAY">Yesterday</option>
                            <option value="THIS_WEEK_SUN_TODAY">This week (Sun - Today)</option>
                            <option value="LAST_7_DAYS">Last 7 days</option>
                            <option value="LAST_14_DAYS">Last 14 days</option>
                            <option value="THIS_MONTH">This month</option>
                            <option value="LAST_30_DAYS">Last 30 days</option>
                            <option value="LAST_MONTH">Last month</option>
                            <option value="ALL_TIME">All time</option>
                        </select>
                    </div>

                </div>
            </div>
        </div>

        <div className="scroll-wrapper-dark">
        <table className="table table-bordered table-hover table-responsive">
            <thead className="thead-light" >
                <tr key="accounts_table" style={{ textAlign: 'center', verticalAlign: 'top'}}>
                    
                    <th key="select" scope="col">Auto-optimize</th>
                    <th key="campaign_name" scope="col" colSpan={2}>Campaign</th>
                    <th key="budget" scope="col">Budget per day {currency['currency']}</th>
                    <th key="status" scope="col">Status</th>
                    <th key="campaign_type" scope="col">Campaign type</th>
                    <th key="impressions" scope="col">Impr.</th>
                    <th key="interactions" scope="col">Interactions</th>
                    <th key="interaction_rate" scope="col">Interaction rate</th>
                    <th key="avg_cost" scope="col">Avg. cost {currency['currency']}</th>
                    <th key="total_cost" scope="col">Cost {currency['currency']}</th>
                    <th key="conversions" scope="col">Conversions</th>
                    <th key="cost_per_conversion" scope="col">Cost / conv. {currency['currency']}</th>
                    <th key="conversion_rate" scope="col">Conv. rate</th>
                </tr>
            </thead>
           
            <tbody>
                {campaignInfo.length > 0 && campaignInfo.map(item => {
                    if (status === "All active") {
                        return(
                    
                            <tr key={item.campaign_id} id={item.campaign_id} value={item.campaign_id} 
                            onClick={onClick}
                            style={{ textAlign: 'center', cursor: 'pointer', 
                            display: item.status === "Active" ? '' : 'none'  }}>
                                
                                <td key={item.process_automation_status} onChange={e => handleChange(item, e)} onClick={(e) => e.stopPropagation()}><input type="checkbox" defaultChecked={item.process_automation_status} /></td>
                                <td> <i className="fas fa-circle" style={{ color: billingStatus ==="APPROVED" ? '#289e01' : '#e55100', display: item.status==="Active" ? '' : 'none'}}></i>
                                <i className="fas fa-pause-circle" style={{ color: '', display: item.status==="Paused" ? '' : 'none'}}></i>
                                <i className="fas fa-times-circle" style={{ color: '#e55100', display: item.status==="Removed" ? '' : 'none'}}></i></td>
                                <td> {item.campaign_name}</td>
                                <td> {currency['currency']} {item.campaign_budget}</td>
                                <td> {billingStatus ==="APPROVED" ? item.status : 'Inactive until billing is set up'}</td>
                                <td> {item.campaign_type}</td>
                                <td> {String(item.impressions).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {String(item.interactions).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {item.interaction_rate}%</td>
                                <td> {item.cpc.toFixed(2)}</td>
                                <td> {String(item.cost).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {String(item.conv).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {item.cost_per_conv.toFixed(0)}</td>
                                <td> {item.conv_rate.toFixed(1)}%</td>
            
                                
                            </tr>
                            
                            )

                    } else if (status === "All but removed") {
                        return(
                    
                            <tr key={item.campaign_id} id={item.campaign_id} value={item.campaign_id} 
                            onClick={onClick}
                            style={{ textAlign: 'center', cursor: 'pointer', 
                            display: item.status === "Active" || item.status === "Paused" ? '' : 'none'  }}>
                                
                                <td key={item.process_automation_status} onChange={e => handleChange(item, e)} onClick={(e) => e.stopPropagation()}><input type="checkbox" defaultChecked={item.process_automation_status} /></td>
                                <td> <i className="fas fa-circle" style={{ color: billingStatus ==="APPROVED" ? '#289e01' : '#e55100', display: item.status==="Active" ? '' : 'none'}}></i>
                                <i className="fas fa-pause-circle" style={{ color: '', display: item.status==="Paused" ? '' : 'none'}}></i>
                                <i className="fas fa-times-circle" style={{ color: '#e55100', display: item.status==="Removed" ? '' : 'none'}}></i></td>
                                <td> {item.campaign_name}</td>
                                <td> {item.campaign_budget}</td>
                                <td> {billingStatus ==="APPROVED" ? item.status : 'Inactive until billing is set up'}</td>
                                <td> {item.campaign_type}</td>
                                <td> {String(item.impressions.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {String(item.interactions.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {item.interaction_rate.toFixed(2)}%</td>
                                <td> {item.cpc.toFixed(2)}</td>
                                <td> {String(item.cost.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {String(item.conv.toFixed(0)).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {item.cost_per_conv.toFixed(0)}</td>
                                <td> {item.conv_rate.toFixed(2)}%</td>
            
                                
                            </tr>

                            
                            
                            )
                    } else {
                        return(
                    
                            <tr key={item.campaign_id} id={item.campaign_id} value={item.campaign_id} 
                            onClick={onClick}
                            style={{ textAlign: 'center', cursor: 'pointer'}}>
                                
                                <td key={item.process_automation_status} onChange={e => handleChange(item, e)} onClick={(e) => e.stopPropagation()}><input type="checkbox" defaultChecked={item.process_automation_status} /></td>
                                <td> <i className="fas fa-circle" style={{ color: billingStatus ==="APPROVED" ? '#289e01' : '#e55100', display: item.status==="Active" ? '' : 'none'}}></i>
                                <i className="fas fa-pause-circle" style={{ color: '', display: item.status==="Paused" ? '' : 'none'}}></i>
                                <i className="fas fa-times-circle" style={{ color: '#e55100', display: item.status==="Removed" ? '' : 'none'}}></i></td>
                                <td> {item.campaign_name}</td>
                                <td> {item.campaign_budget}</td>
                                <td> {billingStatus ==="APPROVED" ? item.status : 'Inactive until billing is set up'}</td>
                                <td> {item.campaign_type}</td>
                                <td> {String(item.impressions).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {String(item.interactions).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {item.interaction_rate}%</td>
                                <td> {item.cpc.toFixed(2)}</td>
                                <td> {String(item.cost).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {String(item.conv).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                <td> {item.cost_per_conv.toFixed(0)}</td>
                                <td> {item.conv_rate.toFixed(1)}%</td>
            
                                
                            </tr>
                            
                            )
                    }
                
                })}

            </tbody>
        </table>
        </div>
        <br/>
    </div>
)}

export default Campaigns;