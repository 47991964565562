// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {useHistory} from 'react-router-dom';

const Home = () => {

    const [token, setToken, removeToken] = useCookies(['mytoken'])
    const [refreshToken, setRefreshToken, removeRefreshToken] = useCookies(['refreshToken'])
    let history = useHistory()

    // Ping backend to wake it up and reduce response time from the first user API request
    fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/ready/', {
                method: 'GET'
    })

    // grab query string
    const queryString = window.location.search;

    // parse que query string's parameters
    const urlParams = new URLSearchParams(queryString)

    // get the code that is the access code of user after authenticating and authorizing permission
    const google_access_code = urlParams.get('code')
    console.log(google_access_code)

    // get the state that is the anti-forgery state token
    const state = urlParams.get('state')
    console.log(state)

    // if there is a code in the parameter of the url
    // send it to the backend with the state
    // where they will be used to get the refresh token
    useEffect(() => {
        if(google_access_code) {
            
            // data to send to the backend
            const data = { 'mytoken': token['mytoken'], 'google_access_code': google_access_code, 'passthrough_val': state}

            fetch('https://google-ads-backend-test-qgiq37b64q-lz.a.run.app/api/get-token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['mytoken']}`
                },
                body: JSON.stringify(data),
                
            })
            .then(resp => resp.json())
            .then(resp => {
                console.log('response from our backend:'+resp)
                // if user has a refresh token
                if (resp.refresh_token ===1) {
                    console.log('user has a refresh token')
                    setRefreshToken('refreshToken', 'True')
                    // redirect user to the accessible accounts page
                    history.push('/googleads-accounts')
                }
            })
            .catch(error => console.log(error))
            
        }
    }, [token, google_access_code, state, history, setRefreshToken])
    
    // START button
    const goStart = () => {
        // if there is a refresh token in the cookies,
        // send user to the Accounts page.
        if(refreshToken['refreshToken']) {
            history.push('/googleads-accounts')
        }
        // if no refresh token but yes mytoken,
        // send user to Google Ads page.
        else if(token['mytoken']) {
            history.push('/googleads')
        }
        // if neither, this means user is not logged in yet,
        // so push user to the login or signup page.
        else {
            history.push('/login')
        }  
    }

    // LEARN MORE button
    const goLearnMore = () => {
        history.push('/learn-more')  
    }

    return (
        
    <div className="container mt-4">
        <br/>
        <h4 className="display-4 text-center mb-4 title">
        Unlock growth with Hai Byte
        </h4>     

        <br/>
        <p className="mb-0" align="center" >
        Hai Byte is an app that will <strong>help you sell more by optimizing your Google 
        Ads </strong>. When you automate campaign optimization, you don't need to spend many hours every week to 
        optimize campaigns and can focus on growing your business.
        </p>
        
        <div className='mt-4' align="center">
            
            <button type="button" className="btn btn-success btn-block" style={{margin:'10px'}}
            onClick={goStart}>GET STARTED</button>
            
            <button type="button" className="btn btn-outline-primary btn-block" style={{margin:'10px'}}
            onClick={goLearnMore}>LEARN MORE</button>

            <br/>
            <br/>

            <h5 align="left">Here's how we can help you:</h5>
            <div className="card">
                <div className="card-body">
                <p className="mb-0" align="left" >
                <strong>Our automatic optimization runs on a daily basis</strong> for the campaigns you opt in. It helps you optimize campaigns by:
                <br/>
                <li>Maximizing the bang for your buck by adjusting ad group bids to ensure that your campaigns spend their budget on ad groups that are the most profitable</li>
                <li>Reducing wasted spend by adding negative keywords for searches that convert poorly</li>
                <li>Increasing ad relevance by adding exact match keywords for searches that you're already capturing to make sure keyword insertion can show them in your headlines</li>
                <br/>
                <strong>Hai Byte Pro and Enterprise users</strong> can additionally benefit from:
                <li>Steering spend towards the most incremental campaigns via automatic MMM-based campaign bid adjustments</li>
                <li>Seeing how many incremental conversions your campaigns steer and how many of the conversions would have happened anyway thanks to our tailored MMM analysis</li>
                </p>
                </div>
            </div>

            <br/>
            <br/>
            <p style={{fontStyle:"italic"}}>
            Hai Byte's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank"  rel="noopener noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements.
            </p>

            <div className='mt-4' align="center">
                <img src="/mascot_bg_blue.webp" loading="lazy" alt="Hai Byte shark mascot image" class="image" />
            </div>
            
        </div>
        
    </div>
)}

export default Home;