// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import {useHistory} from 'react-router-dom';
import { useCookies } from 'react-cookie'

export const Sidebar = () => {
    const [customerId] = useCookies(['customer_id'])
    let history = useHistory()

    const onClick = () => {
        history.push('/campaigns')
    }
    const onClick2 = () => {
        history.push('/performance')
    }
    const onClick3 = () => {
        history.push('/pmax-performance')
    }
    
    if (customerId['customer_id'] != null) {
        return (
            <div className='sidebar'>
                <div className="side-item">
                    <button className="sidebar-btn" aria-current="page" activeClassName="sidebar-btn--active" activeStyle={{fontWeight: 'bold'}}
                        onClick={onClick}>
                        Auto-optimization settings
                    </button>
                </div>
                <div class="sidebar-divider"></div>
                <div className="side-item">
                    <button className="sidebar-btn"  aria-current="page" activeClassName="sidebar-btn--active" activeStyle={{fontWeight: 'bold'}}
                        onClick={onClick2}>
                        Performance analysis
                    </button>
                </div>
                <div class="sidebar-divider"></div>
                <div className="side-item">
                </div>
                {/*
                <div class="sidebar-divider"></div>
                <div className="side-item">
                    <button className="sidebar-btn"  aria-current="page" activeClassName="sidebar-btn--active" activeStyle={{fontWeight: 'bold'}}
                        onClick={onClick3}>
                        PMax performance (testing)
                    </button>
                </div>
                */}
                <div class="sidebar-divider"></div>
            </div>
        )
    } else {
        return(<div></div>)
    }
}

export default Sidebar