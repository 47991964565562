// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     https://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'

export const About = () => {
    return (
        <div className="container mt-4">
        <br/>
        <h4 className="display-4 text-left mb-4 title">
            About Hai Byte
        </h4>
        <br />
        <p className="mb-0">
        Hai Byte's Google Ads automation tool <strong>optimizes your campaigns effortlessly</strong>  and increases your marketing ROI. With Hai Byte, efficiency meets growth. Get ready to soar above the competition, boost your marketing ROI, and watch your business thrive!
        <br/>
        <br/>
        Hai Byte is currently in the open beta testing stage. The tool works well, although there may be some bugs in the 
        user interface. If you notice any bugs, please <a href="https://hai-bytes-exceptional-site.webflow.io/contact-us" target="_blank"  rel="noopener noreferrer">contact us</a> to report the bugs. If you have any features in mind 
        that would make the tool even more useful for you, feel free to send your feature requests to us too!
        </p>
        <br />
        <br />
        <div className="row">
            <div className="col-sm-6">
                <div className="card mb-3" style={{"maxWidth": "540px"}}>
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img src="results_461x461.webp" className="card-img-top" alt="Marketer jumping in excitement because his Google Ads results got so much better thanks to Hai Byte's automation solutions" />
                        </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h5 className="card-title">Are you ready to realize the benefits?</h5>
                            <p className="card-text">Scale your Google Ads success with confidence. Start a 7 day free trial to test it out.
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )
}

export default About